import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  buttonBackground?: string
  buttonName?: string
  buttonText?: string
  buttonUrl?: string
  colorPalette: ColorPalette
  fontTextColor: string
  icon: any
  iconTitle: string
  imageFive: string
  imageFiveAlt: string
  imageFivePill?: string
  imageFiveText: string
  imageFour: string
  imageFourAlt: string
  imageFourPill?: string
  imageFourText: string
  imageOne: string
  imageOneAlt: string
  imageOnePill?: string
  imageOneText: string
  imageSix: string
  imageSixAlt: string
  imageSixPill?: string
  imageSixText: string
  imageThree: string
  imageThreeAlt: string
  imageThreePill?: string
  imageThreeText: string
  imageTitleFive: string
  imageTitleFour: string
  imageTitleOne: string
  imageTitleSix: string
  imageTitleThree: string
  imageTitleTwo: string
  imageTwo: string
  imageTwoAlt: string
  imageTwoPill?: string
  imageTwoText: string
  pillActive?: string
  subtitle: string
  title: string
}

/** const */
const SixImageSection: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      className="container-fluid text-center pt-5 pb-5"
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <div className="container pl-3 pr-3 text-center">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <Icon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", color: `${props.fontTextColor}` }}
        >
          {props.iconTitle}
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mb-3 mt-3"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            color: `${props.fontTextColor}`,
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: `${props.fontTextColor}` }}>
          {props.title}
        </h3>
        <h2 className="contentHeroSubTitle" style={{ color: `${props.fontTextColor}`, opacity: "0.7" }}>
          {props.subtitle}
        </h2>

        {props.buttonUrl && (
          <div className="d-flex justify-content-center mt-5">
            <a
              className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
              href={props.buttonUrl}
              target="_blank"
              style={{
                backgroundColor: `${props.buttonBackground}`,
                border: "0px",
                color: `${props.buttonText}`,
              }}
            >
              {props.buttonName}
            </a>
          </div>
        )}

        <div className="row mt-5 mb-5">
          <div className="col-sm-6">
            <div
              className="card card-site high p-0"
              data-aos="fade"
              style={{
                borderRadius: "8px",
                border: "transparent",
                backgroundColor: "#fff",
                color: "#444444",
              }}
            >
              <div
                className="overflow-auto"
                style={{
                  background: `linear-gradient(${props.colorPalette.cardAccentTop} 20%, ${props.colorPalette.cardAccentBottom} 80%)`,
                }}
              >
                <img
                  src={props.imageOne}
                  width="495"
                  height="200"
                  loading="lazy"
                  decoding="async"
                  alt={props.imageOneText}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="p-4">
                <h3 className="cardHeader-site pb-1" style={{ fontSize: "20px" }}>
                  <strong>{props.imageTitleOne}</strong>
                </h3>

                {props.pillActive && (
                  <h5>
                    <span
                      className="badge badge-secondary"
                      style={{
                        backgroundColor: `${props.colorPalette.buttonNew}`,
                        color: "#fff",
                      }}
                    >
                      {props.imageOnePill}
                    </span>
                  </h5>
                )}

                <h4
                  className="cardText-site pt-0 pb-5 h-100"
                  style={{
                    opacity: "0.8",
                    fontSize: "17px",
                    lineHeight: "28px",
                  }}
                >
                  {props.imageOneText}
                </h4>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div
              className="card card-site high p-0"
              data-aos="fade"
              style={{
                borderRadius: "8px",
                border: "transparent",
                backgroundColor: "#fff",
                color: "#444444",
              }}
            >
              <div
                className="overflow-auto"
                style={{
                  background: `linear-gradient(${props.colorPalette.cardAccentTop} 20%, ${props.colorPalette.cardAccentBottom} 80%)`,
                }}
              >
                <img
                  src={props.imageTwo}
                  width="495"
                  height="200"
                  loading="lazy"
                  decoding="async"
                  alt={props.imageTwoText}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="p-4">
                <h3
                  className="cardHeader-site pb-1"
                  data-aos="fade"
                  style={{ fontSize: "20px", paddingBottom: "10px" }}
                >
                  <strong>{props.imageTitleTwo}</strong>
                </h3>

                {props.pillActive && (
                  <h5>
                    <span
                      className="badge badge-secondary"
                      style={{
                        backgroundColor: `${props.colorPalette.buttonNew}`,
                        color: "#fff",
                      }}
                    >
                      {props.imageTwoPill}
                    </span>
                  </h5>
                )}

                <h4
                  className="cardText-site pt-0 pb-5 h-100"
                  style={{
                    opacity: "0.8",
                    fontSize: "17px",
                    lineHeight: "28px",
                  }}
                >
                  {props.imageTwoText}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-sm-6">
            <div
              className="card card-site high p-0"
              data-aos="fade"
              style={{
                borderRadius: "8px",
                border: "transparent",
                backgroundColor: "#fff",
                color: "#444444",
              }}
            >
              <div
                className="overflow-auto"
                style={{
                  background: `linear-gradient(${props.colorPalette.cardAccentTop} 20%, ${props.colorPalette.cardAccentBottom} 80%)`,
                }}
              >
                <img
                  src={props.imageThree}
                  width="495"
                  height="200"
                  loading="lazy"
                  decoding="async"
                  alt={props.imageThreeText}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="p-4">
                <h3 className="cardHeader-site pb-1" style={{ fontSize: "20px" }}>
                  <strong>{props.imageTitleThree}</strong>
                </h3>

                {props.pillActive && (
                  <h5>
                    <span
                      className="badge badge-secondary"
                      style={{
                        backgroundColor: `${props.colorPalette.buttonNew}`,
                        color: "#fff",
                      }}
                    >
                      {props.imageThreePill}
                    </span>
                  </h5>
                )}

                <h4
                  className="cardText-site pt-0 pb-5 h-100"
                  style={{
                    opacity: "0.8",
                    fontSize: "17px",
                    lineHeight: "28px",
                  }}
                >
                  {props.imageThreeText}
                </h4>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div
              className="card card-site high p-0"
              data-aos="fade"
              style={{
                borderRadius: "8px",
                border: "transparent",
                backgroundColor: "#fff",
                color: "#444444",
              }}
            >
              <div
                className="overflow-auto"
                style={{
                  background: `linear-gradient(${props.colorPalette.cardAccentTop} 20%, ${props.colorPalette.cardAccentBottom} 80%)`,
                }}
              >
                <img
                  src={props.imageFour}
                  width="495"
                  height="200"
                  loading="lazy"
                  decoding="async"
                  alt={props.imageFourText}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="p-4">
                <h3 className="cardHeader-site pb-1" style={{ fontSize: "20px" }}>
                  <strong>{props.imageTitleFour}</strong>
                </h3>

                {props.pillActive && (
                  <h5>
                    <span
                      className="badge badge-secondary"
                      style={{
                        backgroundColor: `${props.colorPalette.buttonNew}`,
                        color: "#fff",
                      }}
                    >
                      {props.imageFourPill}
                    </span>
                  </h5>
                )}

                <h4
                  className="cardText-site pt-0 pb-5 h-100"
                  style={{
                    opacity: "0.8",
                    fontSize: "17px",
                    lineHeight: "28px",
                  }}
                >
                  {props.imageFourText}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-sm-6">
            <div
              className="card card-site high p-0"
              data-aos="fade"
              style={{
                borderRadius: "8px",
                border: "transparent",
                backgroundColor: "#fff",
                color: "#444444",
              }}
            >
              <div
                className="overflow-auto"
                style={{
                  background: `linear-gradient(${props.colorPalette.cardAccentTop} 20%, ${props.colorPalette.cardAccentBottom} 80%)`,
                }}
              >
                <img
                  src={props.imageFive}
                  width="495"
                  height="200"
                  loading="lazy"
                  decoding="async"
                  alt={props.imageFiveText}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="p-4">
                <h3 className="cardHeader-site pb-1" style={{ fontSize: "20px" }}>
                  <strong>{props.imageTitleFive}</strong>
                </h3>

                {props.pillActive && (
                  <h5>
                    <span
                      className="badge badge-secondary"
                      style={{
                        backgroundColor: `${props.colorPalette.buttonNew}`,
                        color: "#fff",
                      }}
                    >
                      {props.imageFivePill}
                    </span>
                  </h5>
                )}

                <h4
                  className="cardText-site pt-0 pb-5 h-100"
                  style={{
                    opacity: "0.8",
                    fontSize: "17px",
                    lineHeight: "28px",
                  }}
                >
                  {props.imageFiveText}
                </h4>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div
              className="card card-site high p-0"
              data-aos="fade"
              style={{
                borderRadius: "8px",
                border: "transparent",
                backgroundColor: "#fff",
                color: "#444444",
              }}
            >
              <div
                className="overflow-auto"
                style={{
                  background: `linear-gradient(${props.colorPalette.cardAccentTop} 20%, ${props.colorPalette.cardAccentBottom} 80%)`,
                }}
              >
                <img
                  src={props.imageSix}
                  width="495"
                  height="200"
                  loading="lazy"
                  decoding="async"
                  alt={props.imageSixText}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="p-4">
                <h3 className="cardHeader-site pb-1" style={{ fontSize: "20px" }}>
                  <strong>{props.imageTitleSix}</strong>
                </h3>

                {props.pillActive && (
                  <h5>
                    <span
                      className="badge badge-secondary"
                      style={{
                        backgroundColor: `${props.colorPalette.buttonNew}`,
                        color: "#fff",
                      }}
                    >
                      {props.imageSixPill}
                    </span>
                  </h5>
                )}

                <h4
                  className="cardText-site pt-0 pb-5 h-100"
                  style={{
                    opacity: "0.8",
                    fontSize: "17px",
                    lineHeight: "28px",
                  }}
                >
                  {props.imageSixText}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default SixImageSection
