import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  icon: any
  iconTitle: string
  title: string
  subtitle: string
  imageTitleOne: string
  imageOne: string
  imageTitleTwo: string
  imageTwo: string
  imageTitleThree: string
  imageThree: string
  imageTitleFour: string
  imageFour: string
  imageTitleFive: string
  imageFive: string
  imageTitleSix: string
  imageSix: string
  buttonUrl?: string
  colorPalette: ColorPalette
  backgroundColorOne: string
  backgroundColorTwo: string
  badge: Boolean
}

/** const */
const GalleryImageSection: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      className="container-fluid text-center pt-5 pb-5"
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <Icon />
        </div>
        <div className="hidden-sm hidden-md hidden-lg hidden-xl">
          {props.badge ? (
            <span
              className="badge badge-secondary ms-0 mt-2 mb-4"
              style={{
                fontSize: "0.9rem",
                verticalAlign: "middle",
                backgroundColor: `${props.colorPalette.buttonNew}`,
              }}
            >
              {props.iconTitle}
            </span>
          ) : (
            <strong>{props.iconTitle}</strong>
          )}
        </div>

        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mb-3 mt-3"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
        />
        <h3 className="contentHeroTitle" style={{ color: `${props.colorPalette.sectionText}` }}>
          {props.title}
        </h3>
        <h4
          className="contentHeroSubTitle mt-0 mb-5"
          style={{ color: `${props.colorPalette.sectionText}`, opacity: "0.6" }}
        >
          {props.subtitle}
        </h4>

        {props.buttonUrl && (
          <div className="d-flex justify-content-center mb-5">
            <a
              className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
              href={props.buttonUrl}
              target="_blank"
              style={{
                backgroundColor: "#ffffff",
                border: "0px",
                color: "#333333",
              }}
            >
              Learn More
            </a>
          </div>
        )}

        <div className="gallery mb-12" id="gallery-1 w-100">
          <div className="mb-3 item">
            <img
              className="img-fluid z-depth-1 rounded"
              src={props.imageOne}
              loading="lazy"
              decoding="async"
              alt={props.imageTitleOne}
              width="346px"
              height="231px"
            />
          </div>
          <div className="mb-3 item">
            <img
              className="img-fluid z-depth-1 rounded"
              src={props.imageTwo}
              loading="lazy"
              decoding="async"
              alt={props.imageTitleTwo}
              width="350px"
              height="525px"
            />
          </div>
          <div className="mb-3 item">
            <img
              className="img-fluid z-depth-1 rounded"
              src={props.imageThree}
              loading="lazy"
              decoding="async"
              alt={props.imageTitleThree}
              width="350px"
              height="525px"
            />
          </div>
          <div className="mb-3 item">
            <img
              className="img-fluid z-depth-1 rounded"
              src={props.imageFour}
              loading="lazy"
              decoding="async"
              alt={props.imageTitleFour}
              width="346px"
              height="231px"
            />
          </div>
          <div className="mb-3 item">
            <img
              className="img-fluid z-depth-1 rounded"
              src={props.imageFive}
              loading="lazy"
              decoding="async"
              alt={props.imageTitleFive}
              width="346px"
              height="231px"
            />
          </div>
          <div className="mb-3 item">
            <img
              className="img-fluid z-depth-1 rounded"
              src={props.imageSix}
              loading="lazy"
              decoding="async"
              alt={props.imageTitleSix}
              width="350px"
              height="525px"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default GalleryImageSection
