import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import SixImageSection from "../components/dynamic-sections/SixImageSection"
import FourImageSmallSection from "../components/dynamic-sections/FourImageSmallSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import TwoBoxSection from "../components/dynamic-sections/TwoBoxSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"

/** svg */
import ApartmentIcon from "../../assets/apartment-icon.svg"
import TeamUpIcon from "../../assets/team-up-icon.svg"
import NorhartIcon from "../../assets/norhart-icon.svg"
import AmenitiesIcon from "../../assets/amenities-icon.svg"
import BadgeCheckIcon from "../../assets/badge-check-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const ServicesPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/services/",
          name: "Services | Norhart",
          image: `${config.siteMetadata.siteUrl}/services/services-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Services | Norhart"
      description="Norhart lifestyle living apartments in Forest Lake, Blaine, Circle Pines, Lexington, and Oakdale, MN,   enrich our residents' lives with incredible amenities and services."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/services/norhart-services-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/services/norhart-services-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Exceptional Amenities"
        subtitle="Enjoy Norhart's Apartment Lifestyle Living"
        imageTitle="Norhart Lifestyle Living"
        image="/services/norhart-service-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Modern Luxury Apartments"
        tagLine="Rent apartments in Forest Lake and Blaine"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <TwoBoxSection
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Lifestyle Living"
        title="Lifestyle Apartments"
        subtitle="Our passion is building exceptional apartments in the Minneapolis area that enrich our residents' lives. Norhart offers valuable services and amenities to enhance your living experience."
        boxImgOne="/apartments/norhart-lexington-lofts-apartments-blaine.webp"
        boxAltOne="Norhart Lexington Loft Apartments in Blaine"
        boxTitleOne="Lexington Lofts Apartments "
        boxAddressOne="9001 Griggs Ave "
        boxCityOne="Lexington, MN 55014"
        boxPhoneOne="651-237-3322"
        boxURLOne="/blaine/lexington-lofts/"
        boxImgTwo="/apartments/norhart-encore-apartments-forest-lake.webp"
        boxAltTwo="Norhart Encore Apartments in Forest Lake"
        boxTitleTwo="Encore Apartments "
        boxAddressTwo="1081 4th St. SW "
        boxCityTwo="Forest Lake, MN 55025"
        boxPhoneTwo="651-243-3041"
        boxURLTwo="/forest-lake/encore-apartments/"
        colorPalette={colorPalette}
      />

      <SixImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={AmenitiesIcon}
        iconTitle="Norhart Amenities"
        title="Can't Miss Experiences"
        subtitle={`If you haven't noticed, we do things differently at Norhart. Our "Prime Directive" is to create an exceptional living experience for our residents and take your #ApartmentLife to the next level.`}
        imageTitleOne="Community Room"
        imageOneText="Have a birthday party, anniversary, or maybe a family reunion and you are looking for a place to host it? Look no further! We have a beautiful community room just for your occasion."
        imageOne="/services/norhart-service-community-room.webp"
        imageOneAlt="Norhart Community Room"
        imageOnePill="Series 2 Apartments"
        imageTitleTwo="Private Movie Room "
        imageTwoText="Time to treat yourself and your family to a movie after a long day's work. Sit back, relax, and enjoy the latest Star Wars, Marvel, or Pixar movie together as a family. #LifeIsGood"
        imageTwo="/services/norhart-service-private-movie-room.webp"
        imageTwoAlt="Norhart Lifestyle Living Apartments Movie Room"
        imageTwoPill="Series 2 Apartments"
        imageTitleThree="Incredible Entertainment"
        imageThreeText="Yeah, you heard right! We have a game room for you to use whenever you feel the need to unwind and relax. How impressive is that! Don't worry about bringing money; it's all FREE! Whoo Hoo!"
        imageThree="/services/norhart-service-incredible-entertainment.webp"
        imageThreeAlt="Norhart Lifestyle Living Apartments Game Room"
        imageThreePill="Series 2 Apartments"
        imageTitleFour="Health and Fitness Center"
        imageFourText="Yeass! Norhart has a fabulous fitness center! No more driving to a gym when you get home. Just put on your workout gear and walk down the hall. So convenient, just another Norhart perk!"
        imageFour="/services/norhart-service-health-and-fitness-center.webp"
        imageFourAlt="Norhart Lifestyle Living Apartments Health and fitness Center"
        imageFourPill="Series 2 Apartments"
        imageTitleFive="Internet Cafe"
        imageFiveText="Friends are coming over. No need to stop at Starbucks before you head to the Mall of America. Just relax and enjoy a warm cup of coffee, tea, or hot cocoa before you head out for the day."
        imageFive="/services/norhart-service-internet-cafe.webp"
        imageFiveAlt="Norhart Lifestyle Living Apartments Internet Cafe."
        imageFivePill="Series 2 Apartments"
        imageTitleSix="Tight-Knit Community "
        imageSixText="We don't just build apartments where you sleep. We create apartments where you build relationships and bond with your neighbors. Sounds amazing right? We think so!"
        imageSix="/services/norhart-service-tight-knit-community.webp"
        imageSixAlt="Norhart Lifestyle Living Apartment Communities"
        imageSixPill="Series 2 Apartments"
        pillActive="Active"
        colorPalette={colorPalette}
        buttonName="Apply Today"
        buttonUrl="https://norhart.twa.rentmanager.com/applynow"
        buttonBackground="#FFFFFF"
        buttonText="#111111"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Residents Say It Best"
        starRating="yes"
        subTitle={`"I had the pleasure of viewing these apartment homes, WOW! They are gorgeous down to the smallest details. Tons of beautiful natural light, very open floor plan and you can turn the lights on and off with your phone!!! Super amazing place to call home!!" - Melody, Norhart Resident`}
        image="/support/norhart-resident-reviews-3.png"
        imageAlt="Norhart Resident Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <FourImageSmallSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={BadgeCheckIcon}
        title="Apartment Nice Little Extras"
        subtitle="We invite you to choose a Norhart apartment as your next home. We have carefully crafted and curated a living experience that will enhance your life in every way possible."
        imageOnePrice="$55/mo"
        imageHeaderOne="Add-on"
        imageTitleOne="Pet Friendly"
        imageSubTitleOne="We know how important your furry friends are :-)"
        imageOne="/apartments/norhart-apartments-pet-friendly.webp"
        imageOneAlt="Norhart Apartments are Pet Friendly"
        imageTwoPrice="$75+/mo"
        imageHeaderTwo="Add-on"
        imageTitleTwo="Parking"
        imageSubTitleTwo="With underground parking no more cold mornings!"
        imageTwo="/apartments/norhart-apartments-underground-parking.webp"
        imageTwoAlt="Norhart Undergroud Parking"
        imageThreePrice="Free"
        imageHeaderThree="Limited"
        imageTitleThree="Outdoor Balcony"
        imageSubTitleThree="Relax outside and soak up the sun and atmosphere."
        imageThree="/apartments/norhart-apartments-outdoor-balcony.webp"
        imageThreeAlt="Norhart Apartment Balcony"
        imageFourPrice="$50+/mo"
        imageHeaderFour="Limited"
        imageTitleFour="Internet"
        imageSubTitleFour="Plug and play with our in-unit WIFI $50+/mo."
        imageFour="/apartments/norhart-apartments-smart-home.webp"
        imageFourAlt="Norhart Smart Apartment App"
        priceActive={true}
        colorPalette={colorPalette}
        imageExtraOne="/apartments/gallery/extras/extras-1.webp"
        imageTitleExtraOne="Norhart Extra Image 1"
        imageExtraTwo="/apartments/gallery/extras/extras-2.webp"
        imageTitleExtraTwo="Norhart Extra Image 2"
        imageExtraThree="/apartments/gallery/extras/extras-3.webp"
        imageTitleExtraThree="Norhart Extra Image 3"
        imageExtraFour="/apartments/gallery/extras/extras-4.webp"
        imageTitleExtraFour="Norhart Extra Image 4"
        imageExtraFive="/apartments/gallery/extras/extras-5.webp"
        imageTitleExtraFive="Norhart Extra Image 5"
        imageExtraSix="/apartments/gallery/extras/extras-6.webp"
        imageTitleExtraSix="Norhart Extra Image 6"
        asoEffect="fade"
        buttonName="View Apartments"
        buttonUrl="/apartments/"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentBottom}
        fontTextColor="#2f5265"
        titleTopLeft="Norhart Living"
        badgeTitle="Great Floor plans"
        title="Beautiful Living Areas"
        subtitle="Choose from one, two, or three-bedroom floor-plans. We carefully designed each space to be functional, luxurious, and delightful. #JustForYou!"
        imageTitle="Norhart Apartment Floor Plans"
        image="/apartments/norhart-apartments-floor-plan.png"
        colorPalette={colorPalette}
        colSizeOne={6}
        colSizeTwo={6}
        bottomMargin={5}
        asoEffect="fade"
      />

      <GalleryImageSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        icon={TeamUpIcon}
        iconTitle="Coming 2022"
        title="Exclusive Joint Ventures"
        subtitle="Norhart teams up with well-known companies to provide our residents with valuable services to enrich the Norhart apartment experience. You're gonna love that!"
        imageTitleOne="Norhart Exclusive Partnerships Image 1"
        imageOne="/apartments/gallery/exclusive-partnerships/norhart-exclusive-partnerships-1.webp"
        imageTitleTwo="Norhart Exclusive Partnerships Image 2"
        imageTwo="/apartments/gallery/exclusive-partnerships/norhart-exclusive-partnerships-2.webp"
        imageTitleThree="Norhart Exclusive Partnerships Image 3"
        imageThree="/apartments/gallery/exclusive-partnerships/norhart-exclusive-partnerships-3.webp"
        imageTitleFour="Norhart Exclusive Partnerships Image 4"
        imageFour="/apartments/gallery/exclusive-partnerships/norhart-exclusive-partnerships-4.webp"
        imageTitleFive="Norhart Exclusive Partnerships Image 5"
        imageFive="/apartments/gallery/exclusive-partnerships/norhart-exclusive-partnerships-5.webp"
        imageTitleSix="Norhart Exclusive Partnerships Image 6"
        imageSix="/apartments/gallery/exclusive-partnerships/norhart-exclusive-partnerships-6.webp"
        buttonUrl=""
        colorPalette={colorPalette}
        badge={true}
      />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Now Open"
        title="Hello. Lexington Lofts"
        subtitle="We invite you to be a part of our newest apartment community in Blaine, MN. At Norhart our teams have designed and built an exceptional living experience from the top down just for you!"
        imageTitleOne="Lexington Lofts Image 1"
        imageOne="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-1.webp"
        imageTitleTwo="Lexington Lofts Image 2"
        imageTwo="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-2.webp"
        imageTitleThree="Lexington Lofts Image 3"
        imageThree="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-3.webp"
        imageTitleFour="Lexington Lofts Image 4"
        imageFour="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-4.webp"
        imageTitleFive="Lexington Lofts Image 5"
        imageFive="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-5.webp"
        imageTitleSix="Lexington Lofts Image 6"
        imageSix="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-6.webp"
        buttonUrl="https://www.norhart.com/blaine/lexington-lofts/"
        colorPalette={colorPalette}
        badge={true}
      />

      <AwardsSection
        header="Norhart Apartments"
        title="Award Winning Apartments"
        subtitle="We invite you to explore our Forest Lake and Blaine Minnesota smart apartments. Once you experience Norhart's smart living, believe me, there is no going back!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default ServicesPage
