import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  title: string
  subtitle: string
  buttonName: string
  buttonUrl?: string

  buttonName2: string
  buttonUrl2?: string

  imageTitle: string
  image: string
  colorPalette: ColorPalette
}

/** const */
const HeroTopSection: React.FC<Props> = (props) => {
  return (
    <div
      className="container-fluid text-center hero"
      style={{
        color: "#FFF",
        background: `linear-gradient(${props.colorPalette.heroTop} 30%, ${props.colorPalette.heroBottom} 70%)`,
      }}
    >
      <div className="heroContainer">
        <h3 className="heroTitle">{props.title}</h3>
        <h3 className="heroSubTitle">{props.subtitle}</h3>
      </div>

      {props.buttonUrl && (
        <div className="d-flex justify-content-center mt-1 mb-4">
          <a
            className="btn btn-md"
            href={props.buttonUrl}
            target="_blank"
            style={{
              backgroundColor: "#FFFFFF",
              border: "0px",
              color: "#555555",
              fontWeight: "BOLD",
            }}
          >
            {props.buttonName}
          </a>

          <span
            style={{
              padding: "10px",
            }}
          ></span>

          <a
            className="btn btn-md"
            href={props.buttonUrl2}
            target="_blank"
            style={{
              backgroundColor: "#5cb85c",
              border: "0px",
              color: "#FFFFFF",
              fontWeight: "BOLD",
            }}
          >
            {props.buttonName2}
          </a>
        </div>
      )}

      <div className="container" data-aos="fade">
        <div className="col-sm-12">
          <img
            loading="eager"
            className="mw-100 h-auto"
            width="800"
            height="400"
            src={props.image}
            alt={props.imageTitle}
          />
        </div>
      </div>
    </div>
  )
}

/** export */
export default HeroTopSection
